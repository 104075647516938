@import url(https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,400;0,700;1,700&display=swap);
.gradient-button-1 {
  color: white;
  padding: 1rem 2.7rem;
  background-image: linear-gradient(
    to right,
    #c09244 0%,
    #d1ae57 51%,
    #d1ae57 100%
  );
}
.loading {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #c09244;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  margin-top: 250px;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.about-contact-us {
  margin-right: 4px;
}

html,
body {
  overflow-x: hidden;
}
body {
  position: relative;
  font-family: "Cormorant Garamond", serif;
}

.logo {
  width: 80px;
  height: auto;
}

.VideoGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
}
.carousel-text {
  text-align: center;
}

.galleyLolade {
  padding-top: 2em;
}
.carousel {
  margin-top: 3em;
}

p.carousel-text-inner {
  font-style: italic;
  font-size: 21px;
  color: #84847c;
  line-height: 1.285714285714286em;
  padding: 1em;
  padding-bottom: 1em;
  margin-bottom: 0;
}

h1.galleryTittle {
  text-align: center;
  padding: 1em;
}

.contact-img {
  background-image: url("https://res.cloudinary.com/tosin/image/upload/v1622149280/detrix/sharegrid-N10auyEVst8-unsplash-scaled.jpg");
  background-size: cover;
  min-height: 40vh;
  width: auto;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  margin-top: 3em;
  margin-bottom: 3em;
}
h1.about-title {
  font-weight: 700;
  text-align: center;
  padding-top: 20px;
}

.about-image {
  width: 100%;
}
.about-grid {
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 2em;
}
.contact-title {
  color: black;
  cursor: pointer;
}

button.btn-primary.btn-outline-primary.btn.Ripple-parent.see-more {
  margin-top: 20px;
  margin-bottom: 20px;
}

.status-message {
  opacity: 0;
}
.success {
  opacity: 1;
  color: green;
}
.failure {
  opacity: 1;
  color: red;
}

.select-customize {
  border: none;
  border-bottom: 1px solid #ced4da;
}

.form-text-bottom {
  text-align: center;
  padding-bottom: 16px;
  padding-top: 32px;
}

.icon-forms {
  font-size: 1.75rem;
}

.form-select {
  display: -webkit-flex;
  display: flex;
}

.centering {
  -webkit-justify-content: center;
          justify-content: center;
}
.contact-tittle-text {
  text-align: center;
  color: white;
  padding-top: 1em;
  font-size: 64px;
  font-weight: 700;
}

img.gridImg {
  width: 100%; /* or max-width: 100%; */
  height: auto;
}
.me {
  background-image: url("https://res.cloudinary.com/tosin/image/upload/dpr_auto,f_auto,w_auto,q_auto/v1622630332/detrix/_P6A2009.jpg");
  background-size: cover;
  min-height: 90vh;
  width: auto;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.muse1 {
  background-image: url("https://res.cloudinary.com/tosin/image/upload/dpr_auto,f_auto,w_auto,q_auto/v1621792591/detrix/P1120768.jpg");
  background-size: cover;
  min-height: 90vh;
  width: auto;
  background-repeat: no-repeat;
  background-position: 30% 30%;
}

.loladeCrew {
  background-image: url("https://res.cloudinary.com/tosin/image/upload/dpr_auto,f_auto,w_auto,q_auto/v1624486691/detrix/IMG_7799_2.jpg");
  background-size: cover;
  min-height: 90vh;
  width: auto;
  background-repeat: no-repeat;
  background-position: 30% 30%;
}

.muse2 {
  background-image: url("https://res.cloudinary.com/tosin/image/upload/dpr_auto,f_auto,w_auto,q_auto/v1621806403/detrix/P1130201.jpg");
  background-size: cover;
  min-height: 90vh;
  width: auto;
  background-repeat: no-repeat;
  background-position: 30% 20%;
}

.chichiCrew {
  background-image: url("https://res.cloudinary.com/tosin/image/upload/dpr_auto,f_auto,w_auto,q_auto/v1624486898/detrix/IMG_5801_1.jpg");
  background-size: cover;
  min-height: 90vh;
  width: auto;
  background-repeat: no-repeat;
  background-position: 30% 30%;
}

.muse2b {
  background-image: url("https://res.cloudinary.com/tosin/image/upload/dpr_auto,f_auto,w_auto,q_auto/v1621792592/detrix/P1130106.jpg");
  background-size: cover;
  min-height: 90vh;
  width: auto;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

img.gridImg {
  width: 100%;
}

.imageGrid {
  display: grid;
  grid-template-columns: 1fr;
}

h3.gridText {
  text-align: center;
  padding-top: 13px;
  padding-bottom: 20px;
  color: black;
}

h1.ImgGridTittle {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.footer-icon {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  text-align: start;
}

a.contact {
  color: black;
}

.footer-icon {
  max-width: 200px;
}

.testimonials {
  padding-top: 16px;
  padding-bottom: 16px;
}

.lowerVideoGrid {
  grid-template-columns: repeat(3, 1fr);
}

a.ftIcon {
  color: black;
}

.container.promos {
  padding: 2em;
}

@media (min-width: 900px) {
  nav {
    max-height: 60px;
  }
  nav.navbar-dark.indigo.navbar.navbar-expand-md {
    padding-left: 40px;
    padding-right: 60px;
  }
  a.nav-link.Ripple-parent.active {
    padding-left: 26px;
    padding-right: 26px;
  }
  p.promo {
    font-size: 30px;
  }

  nav.navbar-dark.indigo.navbar.navbar-expand-md {
    background-color: white !important;
  }

  a.nav-link {
    color: black !important;
  }

  .carousel {
    margin-top: 3em;
  }

  .imageGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 16px;
  }
  nav.navbar-dark.indigo.navbar.navbar-expand-md {
    padding-right: 5em;
  }

  .navbar-brand {
    padding-left: 12px;
  }

  .container.footer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 3em;
    padding-left: 1em;
  }

  .video-margin {
    margin-bottom: 4em;
  }
  .WeddingVideoGrid {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .logo {
    margin-bottom: 12px;
    margin-top: 8px;
  }

  a.nav-link.Ripple-parent.navAdjust {
    padding: 0;
  }

  a.nav-link.Ripple-parent.navAdjust.active {
    padding: 0;
  }
}

@media (max-width: 500px) {
  .loader {
    margin-top: 150px;
  }
  nav.navbar-dark.indigo.navbar.navbar-expand-md {
    background-color: black !important;
  }

  .navbar.navbar-dark .breadcrumb .nav-item .nav-link,
  .navbar.navbar-dark .navbar-nav .nav-item .nav-link {
    color: white;
  }

  .loladeCrew {
    background-size: cover;
    background-position: 60% 60%;
  }

  .chichiCrew {
    background-position: 60% 60%;
  }

  .muse2b {
    background-position: 60% 60%;
  }
  a.nav-link {
    color: black !important;
  }

  nav.navbar-dark.indigo.navbar.navbar-expand-md {
    background-color: white !important;
  }

  .navbar.navbar-dark .navbar-toggler-icon {
    cursor: pointer;
    background-image: url("https://mdbcdn.b-cdn.net/img/svg/hamburger6.svg?color=000");
  }

  .muse1 {
    min-height: 50vh;
  }
  .muse2b {
    min-height: 50vh;
  }
  .muse2 {
    min-height: 50vh;
  }
  .loladeCrew {
    min-height: 50vh;
  }
  .chichiCrew {
    min-height: 50vh;
  }
  .me {
    min-height: 50vh;
  }
  li.nav-item {
    padding-left: 12px;
  }

  .container.footer {
    display: grid;
    grid-template-columns: 1fr;
  }

  .inner2 {
    padding-top: 2em;
  }
  .VideoGrid {
    grid-template-columns: 1fr;
  }
  .video-margin {
    margin-bottom: 6em;
  }

  .logo {
    margin: 0;
  }

  .lowerVideoGrid {
    grid-template-columns: 1fr;
  }
  .about-grid {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 900px) and (min-width: 501px) {
  a.nav-link {
    color: black !important;
  }

  nav.navbar-dark.indigo.navbar.navbar-expand-md {
    background-color: white !important;
  }
  .muse1 {
    min-height: 70vh;
  }
  .muse2b {
    min-height: 70vh;
  }
  .muse2 {
    min-height: 70vh;
  }
  .loladeCrew {
    min-height: 70vh;
  }
  .chichiCrew {
    min-height: 70vh;
  }
  .me {
    min-height: 70vh;
  }

  .imageGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }

  nav.navbar-dark.indigo.navbar.navbar-expand-md {
    padding-right: 2em;
    padding-top: 0;
    padding-bottom: 0;
  }
  li.nav-item {
    padding-left: 12px;
  }

  .container.footer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 3em;
    padding-left: 1em;
  }
  .video-margin {
    margin-bottom: 3em;
  }

  a.nav-link.Ripple-parent.navAdjust {
    padding: 0;
  }

  a.nav-link.Ripple-parent.navAdjust.active {
    padding: 0;
  }
  .about-grid {
    grid-template-columns: 1fr 1fr;
  }
  h2.about-grid-tittle {
    font-size: 26px;
  }
}

